@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Gibson-Bold";
  src: url("../assets/fonts/Gibson-Bold.otf");
}

@font-face {
  font-family: "Gibson-Light";
  src: url("../assets/fonts/Gibson-Light.otf");
}

@font-face {
  font-family: "Gibson-Medium";
  src: url("../assets/fonts/Gibson-Medium.otf");
}

@font-face {
  font-family: "Gibson-Regular";
  src: url("../assets/fonts/Gibson-Regular.otf");
}

@font-face {
  font-family: "Gibson-SemiBold";
  src: url("../assets/fonts/Gibson-SemiBold.otf");
}

@font-face {
  font-family: "Gibson-Thin";
  src: url("../assets/fonts/Gibson-Thin.otf");
}

:root {
  --red: #dc3545;
  --red-bg: #ec45566b;
  --white: #ffffff;
  --black: #000000;
  --blue: #1946bb;
  --light-blue: #4b73f0;
  --guinda: #6f3439;
  --crema: #eddde0;
  --rosaC2: #ffe0e6;
  /* --guinda: #8d949e; */
  --rosaClaro: #ffe6ec;
  /* --rosaClaro: #ccd0d5; */
  /* --rosaAlert: #FFE6EC; */
  --rosaAlert: #ccd0d5;
  --azulTurco: #4fb7b2;
  --morado: #6a0f49;
  /* --morado: #8d949e; */
  --orage: #fdde80;
  --warning: #f9e8c6;
  --light-orange: #ffaf17;
  --green: #d4eddb;
  --green-text: #2a633a;
  --primary-text: #050505;
  --secondary-text: #65676b;
  --input-gray: #f0f2f5;
  --gray-00: #e7e2e4;
  --gray-05: #f2f3f5;
  --gray-10: #ebedf0;
  --gray-20: #dadde1;
  --gray-25: #ccd0d5;
  --gray-30: #bec3c9;
  --gray-45: #8d949e;
  --gray-70: #606770;
  --gray-80: #444950;
  --gray-90: #303338;
  --gray-100: #1c1e21;
  --lightblue-alpha: rgba(75, 115, 240, 0.1);
  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-40: rgba(0, 0, 0, 0.4);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-80: rgba(0, 0, 0, 0.8);
  --overlay-alpha-80: rgba(244, 244, 244, 0.8);
  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
  --animate-duration: 0.7s;
  --primary: #007bff;
  --success: #28a745;
  --danger: #dc3545;
  --warning: #ffc107;
  --headerFooter: #ffc3d0;
}

* {
  margin: 0px;
  padding: 0px;
  /* border:1px solid red; */
  font-family: "Gibson-Regular";
  letter-spacing: 1.1px;
}

a,
a:link,
a:hover,
a:visited,
a:focus-visible {
  text-decoration: none;
  color: unset;
  border: none;
  outline: none;
  padding: 0;
}

body {
  font-family: "Gibson-Regular";
  letter-spacing: 1.1px;
  font-weight: 500;
  min-width: 380px;
  /* overflow: scroll;  */
}

input[type="file"] {
  display: none;
}

.boderBottom--guinda {
  border-bottom: 5px solid var(--headerFooter);
}

.boderBottom--Rosa {
  border-bottom: 5px solid var(--rosaClaro);
}

.boderBottom--gray {
  border-bottom: 5px solid var(--gray-45);
}

.border--guinda {
  border: 1px solid var(--guinda);
  border-radius: 20px;
}

.nav-link {
  color: var(---gray-20) !important;
}

.border-top--rosa {
  border-top: 5px solid var(--rosaClaro);
}

.border-top--gray {
  border-top: 5px solid var(--gray-45);
}

.border-top--guinda {
  border-top: 5px solid var(--headerFooter);
}

/*------------------------------ Background ----------------------- */

.bg-white {
  background-color: var(--white);
}

.bg-guinda {
  background-color: var(--guinda);
}

.bg-crema {
  background-color: var(--crema);
}

.bg-prueba {
  background: var(--gray-00);
  /* linear-gradient(to bottom right, #FFE0E6,#EDDDE0); */
  /* Gradiente suave */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.bg-armarillo {
  background-color: var(--warning);
}

.bg-morado {
  background-color: var(--morado);
}

.bg-rosaClaro {
  background-color: var(--rosaC2);
}

.bg-rosaAlert {
  background-color: var(--rosaAlert);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-orange {
  background-color: var(--orage);
}

.bg-light-orange {
  background-color: var(--light-orange);
}

.bg-green {
  background-color: var(--green);
}

.bg-gray-00 {
  background-color: var(--gray-00);
}

.bg-gray-05 {
  background-color: var(--gray-05);
}

.bg-gray-10 {
  background-color: var(--gray-10);
}

.bg-gray-20 {
  background-color: var(--gray-20);
}

.bg-gray-25 {
  background-color: var(--gray-25);
}

.bg-gray-30 {
  background-color: var(--gray-30);
}

.bg-gray-45 {
  background-color: var(--gray-45);
}

.bg-gray-70 {
  background-color: var(--gray-70);
}

.bg-gray-80 {
  background-color: var(--gray-80);
}

.bg-gray-90 {
  background-color: var(--gray-90);
}

.bg-gray-90 {
  background-color: var(--gray-100);
}

.bg-fondo {
  background-color: var(--gray-25);
}

.bg-red {
  background-color: var(--red-bg);
}

/*--------------- PopUp--------------------------- */

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 640px;
  left: 0px;
  top: 0px;
  background-color: var(--black-alpha-30);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.8px);
  z-index: 9999;
  cursor: auto;
  overflow-y: hidden;
}

.overlay--transparent {
  background-color: transparent !important;
  backdrop-filter: none !important;
}

.overlay--mid {
  z-index: 10 !important;
}

.wizard {
  margin-top: 0px;
  width: 100%;
  max-width: 800px;
  background-color: var(--white);
  /* padding: 15px 30px; */
  box-shadow: 3px 3px 12px 0px var(--shadow-2);
  border-radius: 10px;
  z-index: 100;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  /* overflow-y: scroll; */
}

/*------------------------------ Textos Basado Tamaño ----------------------- */

.text-big {
  font-size: 1.62rem;
}

.text-medium {
  font-size: 1.18rem !important;
}

.text {
  font-size: 1rem !important;
}

.text-small {
  font-size: 13px;
}

/*------------------------------ Texto primario y secundario ----------------------- */

.text-primary {
  color: var(--primary-text) !important;
}

.text-secondary {
  color: var(--secondary-text) !important;
}

/*------------------------------ Textos de tipo negritas  ----------------------- */

.text-bold {
  font-weight: 700;
}

.text-bolder {
  font-weight: 900;
}

/*------------------------------ textos de colores ----------------------- */

.text-guinda {
  color: var(--guinda);
}

.text-morado {
  color: var(--morado);
}

.text-gray {
  color: var(--gray-45);
}

.text-light-blue {
  color: var(--light-blue);
}

.text-orange {
  color: var(--orage);
}

.text-light-orange {
  color: var(--light-orange);
}

.text-red {
  color: var(--red);
}

.text-blue {
  color: var(--blue);
}

.text-green {
  color: var(--green-text);
}

/*------------------------------ textos de colores ----------------------- */

.GibsonBold {
  font-family: "Gibson-Bold";
}

.GibsonBoldItalic {
  font-family: "Gibson-BoldItalic";
}

.GibsonBook {
  font-family: "Gibson-Book";
}

.GibsonBookItalic {
  font-family: "Gibson-BookItalic";
}

.GibsonHeavy {
  font-family: "Gibson-Heavy";
}

.GibsonHeavyItalic {
  font-family: "Gibson-HeavyItalic";
}

.GibsonItalic {
  font-family: "Gibson-Italic";
}

.GibsonLight {
  font-family: "Gibson-Light";
}

.GibsonLightItalic {
  font-family: "Gibson-LightItalic";
}

.GibsonMedium {
  font-family: "Gibson-Medium";
}

.GibsonMediumItalic {
  font-family: "Gibson-MediumItalic";
}

.GibsonRegular {
  font-family: "Gibson-Regular";
}

.GibsonSemiBold {
  font-family: "Gibson-SemiBold";
}

.GibsonSemiBoldItalic {
  font-family: "Gibson-SemiBoldItalic";
}

.GibsonThin {
  font-family: "Gibson-Thin";
}

.GibsonThinItalic {
  font-family: "Gibson-ThinItalic";
}

/*----------- Header Footer ---------------------*/
.headerPrincipal {
  display: flex;
  align-items: center;
  width: 100%;
}

.division1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.division2{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: 2%;
}

.border-top--rosa {
  border-top: 5px solid var(--rosaClaro);
}

.logoMichoacan {
  height: 70px;
}

.logoMichoacan2 {
  height: 65px;
}

.imageRight {
  display: flex;
  padding-left: 5%;
  z-index: 2;
}

.logoGris {
  width: 150px;
}

.logoSello {
  width: 70px;
}

.redes {
  width: 400px;
}

.enlace-login {
  font-size: 15px;
  color: var(--morado);
  text-decoration: underline;
  text-align: center;
}

.enlace-login:hover {
  color: var(--guinda);
  text-decoration: underline;
}

.backgrounPlaca {
  background-image: url(../assets/images/placa.png);
  background-size: cover;
  height: 25px;
}

.footerCenter{
  display: flex;
  margin-left: -10%;
  z-index: 1;
}

/*------------------ botones--------------------------------  */
.cta--subcategorias {
  /* width: 20%; */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 25px;
  border-radius: 20px;
  font-weight: 700;
  border: none;
  background-color: #bec3c9;
  color: var(--gray-80);
}
.cta_redirect {
  max-width: 130px;
  height: 50px;
  cursor: pointer;
  padding: 15px;
  border-radius: 25px;
  font-weight: 500;
  border: none;
  box-shadow: 1px 1px 10px 0px var(--black-alpha-30);
  z-index: 1;
}

.cta_redirectInconformidad {
  max-width: 100px;
  height: 40px;
  cursor: pointer;
  padding: 10px;
  border: none;
  box-shadow: 1px 1px 10px 0px var(--black-alpha-30);
  z-index: 1;
}
.cta_Login {
  max-width: 85px;
  height: 37px;
  cursor: pointer;
  padding: 10px;
  border: none;
  box-shadow: 1px 1px 10px 0px var(--black-alpha-30);
  z-index: 1;
}

.cta--subcategorias:hover {
  background-color: #bec3c9;
  color: #4a001f;
  transform: scale(1.005);
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
}

.cta {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  max-width: 50%;
  height: 65px;
  padding: 10px;
}

.cta--archivo {
  cursor: pointer;
  max-width: 150px;
  max-height: 30px;
  padding: 2px;
  justify-content: center;
  align-items: center;
}

.cta_archivo {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
  color: #fff;
  background-color: #4a001f;
}

.cta--icon {
  padding: 0px !important;
  max-width: 30px !important;
  height: 30px;
}

.cta:hover {
  transform: scale(1.005);
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
}

.cta--light {
  width: 70px;
  height: 25px;
  font-size: 10px;
}

.cta--primary {
  background-color: var(--primary);
  color: white;
}

.cta--success {
  background-color: var(--success);
  color: white;
}

.cta--danger {
  background-color: var(--danger);
  color: white;
}

.cta--warning {
  background-color: var(--warning);
  color: white;
}

.cta--guinda {
  background-color: var(--guinda);
  color: white;
}

.cta--morado {
  background-color: var(--morado)
}
.cta--crema {
  background-color: var(--crema);
  color: white;
}

.cta--rosaC2 {
  background-color: var(--rosaC2);
  color: white;
}

.cta--gray {
  background-color: var(--gray-70);
  color: white;
}

.cta--rosa {
  background-color: var(--rosaClaro);
  color: var(--guinda);
}

.cta--green {
  background-color: var(--green);
  color: white;
}

.cta--red {
  background-color: var(--red);
  color: white;
}

.cta--purple {
  background-color: var(--morado);
  color: white;
}

.cta--disable {
  background-color: var(--gray-45);
  color: white;
  cursor: not-allowed;
}

.cta--short {
  width: 100px;
}

.pill {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--morado) /*  var(--guinda)*/;
  border-radius: 30px;
  padding: 6px 15px;
  background-color: var(--headerFooter) /* var(--rosaAlert) */;
  color: var(--morado);
  /* var(--guinda) */
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10);
}

.pill.active {
  background-color: var(--morado) /* var(--guinda) */;
  color: white;
}

.pill:hover {
  border: 1px solid var(--);
  background-color: var(--morado) /*  var(--guinda */;
  color: white !important;
}

.pill--active {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: white !important;
}

.pill--disabled {
  border: 1px solid var(---gray-30);
  background-color: var(--gray-20);
  color: var(--gray-30) !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

.pill--disabled:hover {
  border: 1px solid var(---gray-30);
  background-color: var(--gray-20);
  color: var(--gray-30) !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

.cta--medium {
  width: 200px;
  border-radius: 12px;
}
.cta--mediumRadius{
  width: 200px;
  border-radius: 5px;

}

/*------------------------------ Icon ----------------------- */

.icon_btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--morado);
  flex: none;
}

.icon_btn:hover {
  color: var(--guinda);
  z-index: 12 !important;
}

/* vista Paciente */

.form-input--searchIcon {
  background-color: var(--gray-00);
  padding: 10px;
  border-radius: 0px 20px 20px 0px;
  /* border: 1px solid red; */
}

.form-input--search {
  width: 100%;
  max-width: 350px;
  height: 45px;
  margin: 10px 0px;
  border: none;
  background-color: var(--gray-00);
  color: #65676b;
  outline: none;
  border-radius: 20px 0px 0px 20px;
  padding: 0px 15px;
  font-size: 14px;
}

.form-input {
  width: 100%;
  max-width: 380px;
  height: 45px;
  margin: 10px 0px;
  border: none;
  background-color: var(--gray-00);
  color: #65676b;
  outline: none;
  border-radius: 40px;
  padding: 0px 15px;
  font-size: 14px;
}

.form-input--error {
  border: 2px solid var(--orage);
  background-color: var(--orage);
}

.form-input:focus {
  border: 1px solid var(--guinda);
}

.form-input--full {
  max-width: inherit !important;
}

.form-input--short {
  width: 100px;
}

.form-input--disabled {
  border: 1px solid var(---gray-20);
  background-color: var(--gray-20);
  color: var(--gray-40) !important;
  cursor: not-allowed;
}

.form-input--disabled:hover {
  border: 1px solid var(---gray-20);
  background-color: var(--gray-20);
  color: var(--gray-40) !important;
  cursor: not-allowed;
}

.form-input--icon {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.form-input_icon {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  background-color: var(--input-gray);
  padding-right: 15px;
}

.card {
  position: relative !important;
  width: 100%;
  border: 1px solid var(--gray-10);
  /* border-radius: 20px !important; */
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-rosa {
  position: relative !important;
  width: 100%;
  border: 1px solid var(--gray-10);
  border-radius: 30px !important;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.contentGrid2 {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: auto;
  height: auto;
  box-shadow: 1px 1px 10px 0px var(--black-alpha-30);
  background-color: white;
  border-radius: 5px 5px 35px 35px;
}

.container_Redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
  width: 100%;
  padding-bottom: 9%;
}
.containerFirst {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  height: auto;
  box-shadow: 1px 1px 10px 0px var(--black-alpha-30);
  background-color: white;
  border-radius: 35px;
}

.first {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.contentFirst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  padding-left: 20px;
}

.image1 {
  height: 80px;
  width: 91px;
  margin-left: 35px;
}

.imageCheck {
  height: 40px;
  width: 45px;
}
.containerimageCheck {
  display: flex;
  width: 19%;
  margin-left: -8%;
  margin-bottom: -50px;
  margin-top: 40px;
  padding: 10px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  z-index: 999 !important;
}

.image3 {
  height: 295px;
  min-width: 285px;
  padding-top: 15px;
  border-radius: 8px 8px 8px 20px;
  object-fit: cover;
  object-position: 100% 100%;
}

.second {
  align-items: center;
  justify-content: center;
  pad: 40px;
}

.card_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 30px;
  margin-right: 30px;
  padding-top: 60px;
  padding-bottom: 50px;
}

.divider {
  display: flex;
  max-width: 100%;
  padding: 1px;
  border-radius: 35px 0px 0px 35px;
  background-color: var(--guinda);
}

.secondary-text {
  text-align: left;
  letter-spacing: 0.8px;
  line-height: 1.2;
  padding-top: 15px;
}

.text-triptico {
  align-items: center;
  letter-spacing: 1px;
  line-height: 1;
  margin-left: 6%;
  margin-right: 6%;
  margin-top: 3%;
  font-size: 1rem;
}

.button-text {
  text-align: center;
  font-size: large;
  line-height: 1.2;
  letter-spacing: 1.5px;
}

.button-text2 {
  text-align: center;
  justify-self: center;
  line-height: 1.2;
  color: white;
  font-weight: 100; 
}


/* .contentFirst::after {
    content: '';
    height: 100%;
    width: 0px;
    top: 0;
    display: block;
    position: absolute;
    right: -50%;
    background-color: transparent;
    border-top: 510px solid transparent;
    border-bottom: 160px solid transparent;
    border-left: 200px solid var(--guinda);
}*/

.float-container--active input {
  border: none;
  font-size: 16px;
  margin: 10px 5px 0 5px;
}

.float-container--active {
  border: solid 1px var(--morado);
  padding: 8px;
  position: relative;
}

.label--active {
  color: var(--morado);
  transform: translate(0, 4px) scale(0.75);
}

input:focus {
  outline: none;
}

.link {
  font-size: 14px;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 5px;
}

.link:hover {
  color: white;
  background-color: var(--morado);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}

/* Handle */

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--gray-90) /* var(--morado) */;
  cursor: pointer;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*---------------Formulario----------------*/
.container_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container_form_Especial {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 20px;
}

.container_inputs {
  display: flex;
}

.card_form {
  width: 60%;
  padding: 20px;
}

.inputs_form {
  max-width: 49%;
}

.inputs_formDonde{
  max-width: 97.5%;
}

.container_inputs2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Espacio entre las columnas */
}

.col .CardImagenInput {
  width: 100%;
}

.col {
  flex: 1;
}

.inputs_archivo {
  width: 49%;
}

.inputs_radio {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.container_inputDonde{
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

/*---------------------------------- Materialui----------------------- */

.MuiFormLabel-colorPrimary {
  color: var(--guinda) /*  var(--gray-90) */ !important;
}

/* .MuiInputBase-colorPrimary{
    border-bottom: 1px solid var(--guinda) !important;
} */

.css-1yhpg23-MuiTableCell-root {
  font-family: "Gibson-Regular" !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  display: none !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  display: none !important;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
}

table {
  /* --bs-table-striped-bg: red; */
  color: var(--guinda);
}

.css-1ygcj2i-MuiTableCell-root {
  pointer-events: none;
  padding: 0px !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #1c1e21 /*  #4A001F */ !important;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.css-1bhi0za-MuiTypography-root-MuiLink-root {
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: #1c1e21 /* #4A001F */ !important;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-color: #1c1e21 /*  #4A001F */ !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #1c1e21 /*  #4A001F */ !important;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.css-1d1r5q-MuiFormHelperText-root {
  color: #1c1e21 /* #4A001F */ !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after,
.css-1a1fmpi-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #1c1e21 /* #4b101f */ !important;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root.Mui-error:after,
.css-1a1fmpi-MuiInputBase-root-MuiInput-root.Mui-error:after {
  border-bottom: 2px solid #d32f2f !important;
}

.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer;
}

/* Radio Button */
input[type="radio"] + label {
  cursor: pointer;
}

input[type="radio"] + label:before {
  content: "";
  width: 25px;
  height: 25px;
  float: left;
  margin: 0px 10px 0 0;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 100%;
}

input[type="radio"]:checked + label {
  color: var(--guinda) /* var(--gray-80) */;
}

input[type="radio"]:checked + label:after {
  content: "";
  width: 23px;
  height: 23px;
  background-color: var(--guinda) /* var(--gray-80) */;
  border: 5px solid white;
  float: left;
  margin-left: -34px;
  margin-top: 1px;
  border-radius: 100%;
}

input[type="radio"]:checked + label::before {
  border: 8px solid var(--guinda);
}

input[type="radio"] {
  display: none;
}

.nombreUnidadMedica {
  margin-left: 55px;
}

.bk-img {
  /* background-image: url("../assets/images/bgFlores.png");
  background-repeat: repeat;
  background-size: cover; */
  padding: 40px 0px 40px 0px;
  min-height: 80vh;
  opacity: 6;
  background-color: var(--gray-00);
}

.imagenLogin {
  object-fit: cover; 
  object-position: center bottom;
  z-index: 1;
  border-radius: 20px 0px 0px 20px;
  
}

.card--height400 {
  max-height: 500px;
}

.cardLogin {
  background-color: white;
  box-shadow: 0px 0px 10px 0px var(--black-alpha-20);
  overflow-y: hidden;
  padding: 4rem;
  height: 589px;
  border-radius: 0px 20px 20px 0px; 
}

.imgLogin {
  object-fit: contain;
  z-index: 1;
}

.text-left {
  text-align: left;
}

button.btn-guinda {
  background-color: var(--morado);
  /*  var(--gray-45); */
  color: white;
  border-radius: 15px;
}

button.btn-guinda:hover {
  background-color: var(--morado) /* var(--gray-80) */;
}

/*------------------------------ Notificaciones ----------------------- */

.notificacion {
  position: relative;
  bottom: 10px;
  margin-bottom: 5px;
  z-index: 1024;
  background-color: var(--white);
  min-width: 360px;
  max-width: 480px;
  min-height: 80px;
  box-shadow: -20px 30px 50px 20px var(--black-alpha-05);
  padding: 10px 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.notificacion--success {
  border-left: 3px solid var(--green);
}

.notificacion--error {
  border-left: 3px solid red;
}

.notificacion--warning {
  border-left: 3px solid var(--light-orange);
}

.notificacion--option {
  border-left: 3px solid var(--morado);
}

.notificacion-animate {
  opacity: 1 !important;
  right: 10px !important;
}

.notification-animate-enter {
  opacity: 0;
  right: 0;
}

.notification-animate-enter-active {
  opacity: 1;
  right: 10px;
  transition: all 200ms;
}

.notification-animate-exit {
  opacity: 1;
  right: 10px;
}

.notification-animate-exit-active {
  opacity: 0;
  right: 0px;
  transition: all 200ms;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: var(--guinda) /* var(--gray-45)  */ !important;
}

.notificacions {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  bottom: 0px;
  right: 0px;
}

.css-1ygcj2i-MuiTableCell-root {
  text-align: center !important;
}

.bg-rosaClaro {
  background-color: var(--rosaC2);
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid var(--guinda);
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.boxModals {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 25px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow: hidden;
  overflow-y: scroll;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.cerrarSesion {
  margin-top: 45vh !important;
}

/* .user{
    margin-top: 70% !important
    }
 */
.textarea {
  overflow: hidden;
}

.swal2-container {
  z-index: 100002 !important;
}

td.break {
  word-break: break-all;
}

.slider-imagen {
  height: 900px;
  width: 100%;
}

.boderBottom--Rosa {
  border-bottom: 5px solid var(--rosaClaro);
}

.nav-link {
  color: var(---gray-20) !important;
}

.logoGuinda {
  width: 30px;
}

.logo-header {
  width: 150px;
}

.itemNav-header {
  padding: 5px;
  cursor: pointer;
  color: var(--guinda);
}

.itemNav-header:hover {
  background-color: var(--rosaClaro);
  color: var(--guinda);
  border-radius: 5px;
  font-weight: 700;
}

.itemDropDown-header {
  padding: 5px !important;
  color: var(--guinda);
}

.itemDropDown-header:hover {
  cursor: pointer;
  color: var(--guinda);
  font-weight: 700;
  background-color: var(--rosaClaro);
}

.dropdown {
  padding: 5px;
  border-radius: 5px;
  color: var(--guinda);
}

.dropdown:hover {
  color: var(--guinda);
  font-weight: 700;
  background-color: var(--rosaClaro);
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 270px;
  max-height: max-content;
  overflow: auto;
  z-index: 100;
  padding: 10px 15px;
  background-color: var(--white);
  border-right: 1px solid var(--gray-20);
}

.menu_item {
  display: flex;
  align-items: center;
  margin: 5px 5px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  color: var(--secondary-text);
  font-family: "Montserrat";
  font-weight: 700;
}

.menu_item svg {
  font-size: 22px;
}

.menu_item:hover .badge {
  background-color: var(--white);
  color: var(--blue);
}

.menu_item:hover {
  background-color: var(--guinda);
  color: white;
  stroke: white;
}

.menu_item:hover svg path {
  stroke: white;
}

.menu_item--disable {
  color: var(--gray-20);
  cursor: not-allowed;
}

.menu_item--disable:hover {
  background-color: var(--gray-45);
  color: white;
  stroke: white;
}

.menu_item .toggle {
  font-size: 15px;
}

.menu_item .toggle:hover {
  border-radius: 50%;
  background-color: white;
  color: var(--blue);
}

.brand {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 40px;
  padding-left: 10px;
  font-family: "Montserrat" !important;
  font-size: 1.32rem;
}

.brand img {
  width: 90%;
}

.border-bottom--guinda {
  border-bottom: 2px solid var(--guinda);
}

.border-bottom--rosa {
  border-bottom: 5px solid var(--rosaClaro);
}

.css-zqcytf-MuiButtonGroup-root {
  box-shadow: none !important;
}

.pill-principal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px 15px;
  background-color: var(--azulTurco) /* var(--rosaAlert) */;
  color: var(--white);
  /* var(--guinda) */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pill-principal.active-principal {
  background-color: var(--morado) /* var(--guinda) */;
  color: white;
}

.pill-principal:hover {
  border: 1px solid var(--);
  background-color: var(--morado) /*  var(--guinda */;
  color: white !important;
}

.css-dorvgo {
  color: "#ffc3d0" !important;
}

/*--------------------------------------------------*/
.contentDesign {
  display: flex;
  width: 100%;
  margin-bottom: -22%;
  margin-top: -10.2%;
}

.imageHome {
  height: 100%;
  width: 100%;
}

.contentRedirect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
}

.containerCheckRedirect {
  display: flex;
  margin-bottom: -18%;
  margin-top: 17%;
  padding: 10px;
  z-index: 1 !important;
  width: 100%;
  justify-content: center;
}

.cardRedirect {
  width: 300px;
  padding-top: 35px;
  margin-top: 13%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--guinda);
}

.imageRedirectContain {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 30px;
}

.imageRedirect2 {
  height: 125px;
  width: 136px;
}

.textRedirect {
  text-align: center;
  color: var(--guinda);
  font-size: "16px";
  font-weight: 600;
}

.textRedirect2 {
  text-align: center;
  color: var(--secondary-text);
  font-size: "16px";
}

/*-----------------------HOME---------------------------*/
.container_Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
  width: 100%;
}

.container_Formulario {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 12%;
}
.contentGrid1 {
  display: flex;
  flex-direction: column;
  width: 630px;
  box-shadow: 2px 2px 10px 0px var(--black-alpha-30), -2px -2px 0px 0px transparent;
  background-image: url("../assets/images/BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  border-radius: 1px 1px 35px 35px;
  margin-top: 4%;
}

.headerCardHome {
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 1;
}

.cardHome {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-left: 16%;
  margin-right: 16%;
  margin-top: 24%;
  margin-bottom: 5%;
  border-radius: 20px;
  color: var(--gray-80);
  box-shadow: 3px 3px 10px 0px var(--black-alpha-30);
  background-color: rgba(255, 255, 255, 0.8);
}

.textHome1 {
  text-align: center;
  letter-spacing: 0.7px;
  line-height: 1.1;
  color: var(--secondary-text);
  width: 100%;
}

.resaltado {
  color: var(--guinda);
  font-weight: 700;
  font-size: 1.1rem;
}

.secondCardHome {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 5%;
  padding: 20px;
  border-radius: 20px;
  color: var(--gray-80);
}

.textHome2 {
  text-align: left;
  letter-spacing: 1px;
  line-height: 1;
  color: var(--guinda);
  margin-left: 2%;
}

.ctaHomeContain {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 2%;
  margin-top: -8%;
}

.ctaHome {
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  height: 70px;
  width: 155px;
  padding: 10px;
  background-image: url(../assets/images/Boton.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.ctaHometext {
  text-align: center;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 1px;
  color: var(--guinda);
}

.container-pdf {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.container-pdf .pdf-item {
  width: 100%;
}

.container-pdf .pdf-item p {
  font-size: 1rem;
  color: #6a0f49;
  text-align: justify;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  margin-top: 5%;
}

@media (max-width: 768px) {
  .container-pdf {
    grid-template-columns: 1fr;
  }

  .container-pdf .pdf-item {
    height: auto;
  }

  .container-pdf .pdf-item p {
    font-size: 0.875rem;
    padding: 0.75rem;
  }
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(163, 125, 125, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}
