/* // XX-Large devices (larger desktops, 1400px and up) */

@media (min-width: 2000px) {
}

@media (max-width: 1885px) {
  .container_Home {
    height: 90vh;
  }
  .container_Redirect {
    height: 90vh;
  }
}

@media (max-height: 800px) {
  .container-pdf .pdf-item {
    margin-bottom: 6%;
  }
}

@media (max-width: 1370px) {
  .container-pdf .pdf-item {
    margin-bottom: 6%;
  }
  .pill {
    padding: 12px 15px;
    font-size: 12px;
  }

  .filaCurp {
    margin-left: 18px !important;
  }

  .nombreUnidadMedica {
    margin-left: 30px;
  }

  .cerrarSesion {
    margin-top: 35vh !important;
  }
}

@media (max-width: 1366px) {
  .cerrarSesion {
    margin-top: 35vh !important;
  }
}

@media (max-width: 1300px) {
  .container_inputs2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pill {
    padding: 12px 15px;
    font-size: 12px;
  }

  .filaCurp {
    margin-left: 25px !important;
  }

  .cerrarSesion {
    margin-top: 25vh !important;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */

@media (max-width: 1200px) {
  .inputs_archivo {
    width: 90%;
  }

  
}

/* // Large devices (desktops, 992px and up) */

@media (max-width: 992px) {
  .inputs_archivo {
    width: 100%;
  }
  .card_form {
    width: 90%;
    padding: 20px;
  }

  .textRedirect {
    font-size: 0.7rem;
  }

  .textRedirect2 {
    font-size: 0.7rem;
  }

  .imageRedirect2 {
    height: 100px;
    width: 111px;
  }

  .ctaHometext {
    font-size: 0.7rem;
  }

  .ctaHome {
    font-weight: 500;
    height: 65px;
    width: 130px;
    padding: 5px;
  }
  .cardLogin {
    height: 100%;

  }
}

@media (max-width: 900px) {
}

/* // Large devices iphone 12 pro (desktops, 992px and up) */

@media (max-width: 850px) {
  /* header */
}

/* // Medium devices (tablets, 768px and up) */

@media (max-width: 800px) {
}

@media (max-width: 767px) {
  .contentGrid1 {
    width: 80%;
  }

  .container_Home {
    height: 90vh;
  }
  .container_Redirect {
    height: 100vh;
  }
  .container_Formulario {
    height: 100%;
  }
  .headerPrincipal {
    flex-direction: column;
  }

  .contentGrid {
    flex-direction: column;
  }

  .contentGrid3 {
    flex-direction: column;
    width: 100%;
  }

  .containerimageCheck {
    margin-left: 39%;
  }

  .card_redirect {
    width: 50%;
    margin-left: 25%;
  }

  .imageRedirect {
    margin-left: 27%;
  }

  .container_inputs {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .inputs_form {
    max-width: 100%;
  }

  .container_inputs2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .inputs_archivo {
    width: 90%;
  }

  .footerCenter {
    margin-left: 0%;
    margin-top: 3%;
  }
  .cardLogin {
    padding: 2rem;
    width: 90%;
    border-radius: 0px 0px 20px 20px;
    height: 100%;

  }
  .imagenLogin {
    border-radius: 20px 20px 0px 0px;
  }
  .division2 {
    margin-top: 3%;
  }
  .division1 {
    justify-content: space-between;
    width: 100%;
    margin-left: 1%;
  }  
}

@media (max-width: 726px) {
}

@media (max-width: 637px) {
  .contentGrid2 {
    width: 80%;
  }
  .containerCheckRedirect {
    margin-bottom: -23%;
  }
  .cardRedirect {
    width: 70%;
  }
}

@media (min-width: 600px) {
}

@media (max-width: 576px) {
}

@media (max-width: 470px) {
  .contentGrid {
    flex-direction: column;
  }

  .contentGrid3 {
    flex-direction: column;
    width: 100%;
  }

  .containerimageCheck {
    margin-left: 39%;
  }

  .card_redirect {
    width: 50%;
    margin-left: 25%;
  }

  .imageRedirect {
    margin-left: 27%;
  }

  .container_inputs {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .inputs_form {
    max-width: 100%;
  }

  .container_inputs2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .inputs_archivo {
    width: 90%;
  }

  .text-triptico {
    font-size: 0.9rem;
  }

  .resaltado {
    font-size: 1.1rem;
  }

  .contentGrid1 {
    margin-top: 10%;
  }
}

@media (max-width: 300px) {
}
